<template>
  <v-expansion-panel>
    <expansion-panel-header
      :interview="interviews"
      :interviewId="interviewId"
    />
    <v-expansion-panel-content class="mt-12">
      <v-row v-if="isInterviewer" class="px-5">
        <v-col cols="12" md="8" xl="9">
          <v-btn
            v-if="selectedCandidate.status === 'approved'"
            small
            color="primary"
            @click="openSendProposalDialog()"
          >
            {{ $t("send_proposal") }}
          </v-btn>
          <!-- <v-btn
            v-if="selectedCandidate.status === 'approved'"
            text
            small
            color="primary"
            @click="approve('approved')"
          >
            {{ $t("hire") }}
          </v-btn> -->
        </v-col>
        <v-col cols="12" md="4" xl="3" class="text-right">
          <v-btn
            v-if="selectedCandidate.status === 'finished'"
            small
            color="primary"
            :loading="isLoading"
            @click="approve('approved')"
          >
            {{ $t("approve") }}
          </v-btn>
          <v-btn
            v-else-if="selectedCandidate.status === 'approved'"
            small
            outlined
            color="primary"
            @click="approve('finished')"
          >
            {{ $t("reverse_approval") }}
          </v-btn>
          <v-btn
            v-if="selectedCandidate.status === 'finished'"
            small
            text
            color="primary"
            @click="openRejectDialog()"
          >
            {{ $t("reject") }}
          </v-btn>
          <v-btn
            v-if="selectedCandidate.status === 'rejected'"
            outlined
            small
            color="primary"
            @click="approve('finished')"
          >
            {{ $t("re_select") }}
          </v-btn>
        </v-col>
      </v-row>

      <v-row class="px-5">
        <v-col cols="12" md="8" xl="9">
          <classification
            :interviewId="interviewId"
            :interviews="interviews"
            :selectedCandidate="selectedCandidate"
            :selectedRecruter="selectedRecruter"
            :isCompleted="isCompleted"
            :isRunning="isRunning"
            :isInterviewer="isInterviewer"
            :isObserver="isObserver"
            class="mb-10"
          />

          <comments
            :interviewId="interviewId"
            :interviews="interviews"
            :selectedCandidate="selectedCandidate"
            :selectedRecruter="selectedRecruter"
            :selectedStage="selectedStage"
            :isCompleted="isCompleted"
            :isRunning="isRunning"
            class="mb-12"
          />
        </v-col>

        <v-col cols="12" md="4" xl="3" class="mr-0 pr-0">
          <results
            :interviewId="interviewId"
            :interviews="interviews"
            :selectedCandidate="selectedCandidate"
            :selectedRecruter="selectedRecruter"
            :selectedStage="selectedStage"
            :isCompleted="isCompleted"
            :isRunning="isRunning"
            :isInterviewer="isInterviewer"
            :isRunningButNotJoined="isRunningButNotJoined"
            @chageStage="chageStage"
            @updatedState="isLoading2 = false"
          />
          <recruter-list
            :interviewId="interviewId"
            :interviews="interviews"
            :selectedCandidate="selectedCandidate"
            :selectedRecruter="selectedRecruter"
            :isCompleted="isCompleted"
            :isRunning="isRunning"
            :isInterviewer="isInterviewer"
            class="mb-10"
            @select="selectRecruter"
          />
        </v-col>

        <v-col cols="12" md="12">
          <div
            v-if="!isCompleted && isInterviewer"
            v-show="!isRunning"
            class="text-right mt-12 mb-10"
          >
            <v-btn
              text
              small
              color="primary"
              class="mt-12"
              @click="openCancelDialog()"
            >
              {{ $t("cancel") }}
            </v-btn>
            <v-btn
              small
              color="primary"
              class="mt-12"
              @click="openFinishDialog()"
            >
              {{ $t("finish") }} {{ $t("interview") }}
            </v-btn>
          </div>
        </v-col>

        <!-- <v-col cols="12" md="12" class="text-right px-10 py-10">
          <v-btn text small color="primary">
            {{ $t("cancel") }}
          </v-btn>
          <v-btn small color="primary">
            {{ $t("finish") }}
          </v-btn>
        </v-col> -->
      </v-row>
    </v-expansion-panel-content>

    <progress-dialog :processing="isLoading" />
  </v-expansion-panel>
</template>

<script>
import { API_URL } from "@/api";
import { mapGetters } from "vuex";
import { UPDATE_STATUS_INTERVIEW_MUTATION } from "../../../graphql/Mutation.gql";
import ExpansionPanelHeader from "./ExpansionPanelHeader.vue";
import Classification from "./Classification.vue";
import Results from "./Results.vue";
import RecruterList from "../RecruterList.vue";
import Comments from "./Comments.vue";

export default {
  name: "ExpansionPanel",

  components: {
    ExpansionPanelHeader,
    Classification,
    Results,
    RecruterList,
    Comments,
  },

  props: {
    interviewId: { type: String },
    interviews: { type: Array },
    isCompleted: { type: Boolean },
  },

  data: () => ({
    apiUrl: API_URL,
    noteDialog: false,
    knowledge: 40,
    selectedCandidate: undefined,
    selectedRecruter: undefined,
    selectedStage: undefined,
    joined: false,
    master: false,
    isLoading: false,
    isLoading2: false,
  }),

  watch: {
    interviews(updatedInterview) {
      // const FIRST_ELEMENT = 0;
      this.getCurrentSelected(updatedInterview);
      this.setCurrentSelectedRecruter(updatedInterview);

      const CURRENT_STAGE = this.getCurrentStage(this.selectedCandidate);
      this.selectedStage = CURRENT_STAGE.name;

      if (CURRENT_STAGE.state !== "running") {
        this.joined = false;
        this.master = false;
      } else {
        clearInterval(this.cron);
      }
    },

    selectedCandidate(val) {
      const CURRENT_STAGE = this.getCurrentStage(val);

      this.selectedRecruter = CURRENT_STAGE.recruters.find(
        (findRecruter) => findRecruter.user.id === this.selectedRecruter.user.id
      );
    },

    joined(val) {
      if (!val) {
        // eslint-disable-next-line no-undef
        Fire.$emit("left");
      }
    },
  },

  created() {
    const FIRST_ELEMENT = 0;
    const CURRENT_STAGE = this.getCurrentStage(this.interviews[FIRST_ELEMENT]);

    this.selectedStage = CURRENT_STAGE.name;

    this.selectedCandidate = this.interviews[FIRST_ELEMENT];
    const logggedRecruter = CURRENT_STAGE.recruters.find(
      (findRecruter) => findRecruter.user.id === this.userId
    );

    if (logggedRecruter) {
      this.selectedRecruter = logggedRecruter;
      this.selectedRecruter.isObserver = false;
    } else if (CURRENT_STAGE.observers.length > 0) {
      this.selectedRecruter = CURRENT_STAGE.observers.find(
        (findRecruter) => findRecruter.user.id === this.userId
      );
      this.selectedRecruter.isObserver = true;
    } else {
      this.selectedRecruter = CURRENT_STAGE.recruters[FIRST_ELEMENT];
    }
  },

  computed: {
    ...mapGetters({
      currentUser: "auth/getCurrentUser",
    }),
    userId() {
      return this.$root.$data.userId;
    },
    isGroup() {
      return this.interviews.length > 1;
    },
    buttonActions() {
      const currentStage = this.selectedCandidate.stages.find(
        (findStage) => findStage.name === this.selectedCandidate.currentStage
      );
      if (currentStage.state === "running" && this.master) {
        return { text: this.$t("pause"), icon: "mdi-pause", value: "pause" };
      } else if (
        currentStage.state === "running" &&
        !this.master &&
        !this.joined
      ) {
        return {
          text: this.$t("interviewParticipate"),
          icon: "mdi-play",
          value: "join",
        };
      } else if (
        currentStage.state === "running" &&
        !this.master &&
        this.joined
      ) {
        return {
          text: this.$t("Goout"),
          icon: "mdi-logout",
          value: "left",
        };
      } else if (
        this.isObserver &&
        currentStage.state !== "running" &&
        !this.joined
      ) {
        return {
          text: this.$t("interviewParticipate"),
          icon: "mdi-play",
          value: "join",
        };
      }
      return { text: this.$t("start"), icon: "mdi-play", value: "running" };
    },

    isRunning() {
      const currentStage = this.selectedCandidate.stages.find(
        (findStage) => findStage.name === this.selectedCandidate.currentStage
      );

      return currentStage.state === "running" && this.joined;
    },

    isStageRunning() {
      const currentStage = this.selectedCandidate.stages.find(
        (findStage) => findStage.name === this.selectedCandidate.currentStage
      );

      return currentStage.state === "running";
    },

    isRunningButNotJoined() {
      const currentStage = this.selectedCandidate.stages.find(
        (findStage) => findStage.name === this.selectedCandidate.currentStage
      );

      return currentStage.state === "running" && !this.joined;
    },

    isInterviewer() {
      const currentStage = this.selectedCandidate.stages.find(
        (findStage) => findStage.name === this.selectedCandidate.currentStage
      );

      const interviewer = currentStage.recruters.find(
        (findRecruter) => findRecruter.user.id === this.userId
      );

      return !!interviewer;
    },

    isObserver() {
      const currentStage = this.selectedCandidate.stages.find(
        (findStage) => findStage.name === this.selectedCandidate.currentStage
      );

      const interviewer = currentStage.observers.find(
        (findObserver) => findObserver.user.id === this.userId
      );

      return !!interviewer;
    },

    isMaster() {
      return this.master && this.joined;
    },
  },

  beforeDestroy() {
    clearInterval(this.cron);
  },

  methods: {
    editEmail(candidateEmail) {
      const params = {
        interviewId: this.interviewId,
        candidateEmail,
      };
      // eslint-disable-next-line no-undef
      Fire.$emit("EditEmailDialog", params);
    },
    async approve(status) {
      this.isLoading = true;
      const candidates = this.getCandidateIdsFromInterviews();
      try {
        await this.$apollo.mutate({
          mutation: UPDATE_STATUS_INTERVIEW_MUTATION,
          variables: {
            interviewUpdateStatusInput: {
              interviewId: this.interviewId,
              candidates,
              status,
            },
          },
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },

    getCandidateIdsFromInterviews() {
      const candidates = this.interviews.map((mapInterview) => {
        return mapInterview.interviewee
          ? mapInterview.interviewee.id
          : mapInterview.email;
      });

      return candidates;
    },

    getCurrentSelected(updatedInterview) {
      const FIRST_ELEMENT = 0;

      const selectedCandidateIndex = updatedInterview.findIndex(
        (findInterview) =>
          findInterview.interviewee
            ? findInterview.interviewee.id ===
              this.selectedCandidate.interviewee.id
            : findInterview.email === this.selectedCandidate.email
      );

      this.selectedCandidate =
        selectedCandidateIndex > -1
          ? updatedInterview[selectedCandidateIndex]
          : updatedInterview[FIRST_ELEMENT];
    },

    setCurrentSelectedRecruter(updatedInterview) {
      const FIRST_ELEMENT = 0;
      const CURRENT_STAGE = this.getCurrentStage(
        updatedInterview[FIRST_ELEMENT]
      );

      let oldSelectedRecruter = CURRENT_STAGE.recruters.find(
        (findRecruter) => findRecruter.user.id === this.selectedRecruter.user.id
      );

      if (oldSelectedRecruter) {
        this.selectedRecruter = oldSelectedRecruter;
        this.selectedRecruter.isObserver = false;
      } else {
        oldSelectedRecruter = CURRENT_STAGE.observers.find(
          (findObserver) =>
            findObserver.user.id === this.selectedRecruter.user.id
        );
        this.selectedRecruter = oldSelectedRecruter;
        this.selectedRecruter.isObserver = true;
      }
    },

    chageStage(stage) {
      const FIRST_ELEMENT = 0;
      const logggedRecruter = stage.recruters.find(
        (findRecruter) => findRecruter.user.id === this.userId
      );

      this.selectedStage = stage.name;

      if (logggedRecruter) {
        this.selectedRecruter = logggedRecruter;
        this.selectedRecruter.isObserver = false;
      } else if (stage.observers.length > 0) {
        this.selectedRecruter = stage.observers.find(
          (findRecruter) => findRecruter.user.id === this.userId
        );
        this.selectedRecruter.isObserver = true;
      } else {
        this.selectedRecruter = stage.recruters[FIRST_ELEMENT];
      }
    },

    getCurrentStage(interview) {
      return interview.stages.find(
        (findStage) => findStage.name === interview.currentStage
      );
    },

    selectRecruter(recruter) {
      this.selectedRecruter = recruter;
    },

    updateState(state) {
      this.isLoading2 = true;
      if (state === "join") {
        this.joined = true;
        // eslint-disable-next-line no-undef
        Fire.$emit("joined");
      } else if (state === "left") {
        this.isLoading2 = false;
        this.joined = false;
        // eslint-disable-next-line no-undef
        Fire.$emit("left");
      } else {
        // eslint-disable-next-line no-undef
        Fire.$emit("startOrPauseInterviewInResultComponents", state);

        if (state === "running") {
          this.joined = true;
          this.master = true;
          // eslint-disable-next-line no-undef
          Fire.$emit("joined");
        }

        if (this.isRunning) {
          // eslint-disable-next-line no-undef
          Fire.$emit("left");
        }
      }
    },

    openOptionsReSchedule() {
      const params = {
        selectedCandidate: this.selectedCandidate,
        interviews: this.interviews,
        interviewId: this.interviewId,
      };
      // eslint-disable-next-line no-undef
      Fire.$emit("OptionsReSchedule", params);
    },

    openFinishDialog() {
      const params = {
        interviewId: this.interviewId,
        interviews: this.interviews,
      };
      // eslint-disable-next-line no-undef
      Fire.$emit("FinishInterview", params);
    },

    openCancelDialog() {
      const params = {
        interviewId: this.interviewId,
        interviews: this.interviews,
      };
      // eslint-disable-next-line no-undef
      Fire.$emit("CancelInterview", params);
    },

    openRejectDialog() {
      const params = {
        interviewId: this.interviewId,
        selectedCandidate: this.selectedCandidate,
      };
      // eslint-disable-next-line no-undef
      Fire.$emit("rejectCandidateDialod", params);
    },

    splitName(name) {
      return name.split(" ")[0];
    },

    openSendProposalDialog() {
      const params = {
        interviewId: this.interviewId,
        selectedCandidate: this.selectedCandidate,
      };
      // eslint-disable-next-line no-undef
      Fire.$emit("SendProposalDialog", params);
    },
  },
};
</script>

<style scoped>
.results {
  background-color: rgba(253, 240, 215, 0.7);
}
</style>
