<template>
  <div>
    <div class="d-flex flex-column mb-12">
      <div class="title">{{ $t("what_was_assessed") }}</div>
    </div>

    <div class="d-flex flex-column justify-space-between">
      <template
        v-for="(classification, index) in selectedRecruter.classification"
      >
        <v-row :key="index">
          <v-col cols="12" md="10">
            <div class="d-flex flex-row justify-space-between">
              <div class="text--secondary" style="width: 80%">
                {{ index + 1 }}. {{ classification.designation }}
              </div>
              <div class="primary--text text-right" style="width: 20%">
                {{ classification.percentageWeight }}%
              </div>
            </div>
            <div class="mt-5">
              <v-slider
                v-model="classification.points"
                :label="$t('level_of_satisfaction')"
                step="1"
                thumb-label="always"
                :thumb-color="thumbColor(classification.points)"
                :thumb-size="22"
                readonly
              ></v-slider>
            </div>
          </v-col>
        </v-row>
      </template>
    </div>
  </div>
</template>

<script>
import { API_URL } from "@/api";
export default {
  name: "Classification",

  props: {
    interviewId: { type: String },
    interviews: { type: Array },
    selectedCandidate: { type: Object },
    selectedRecruter: { type: Object },
  },

  data: () => ({
    apiUrl: API_URL,
    value: 0,
    classification: {
      designation: "",
      points: 0,
      percentageWeight: null,
      createdBy: null,
    },
  }),

  created() {},

  computed: {
    userId() {
      return this.$root.$data.userId;
    },
  },

  methods: {
    thumbColor(points) {
      if (points < 40) return "red";
      if (points < 50) return "orange";
      if (points < 75) return "indigo";
      if (points < 100) return "teal";
      return "green";
    },
  },
};
</script>